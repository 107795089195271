body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Urbanist";
  src: local("Urbanist"),
   url("./fonts/Urbanist/Urbanist-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}