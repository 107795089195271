/* For desktop: */

.font-face-sometypemono {
  font-family: "Montserrat";
}

.font-face-forosans {
  font-family: "Urbanist";
  font-size: 15px;
  color: #525252;
  line-height: 18px;
}

body {
  /* height: 100vh; */
  max-width: 100%;
  margin: 0;
  padding: 0;

  color: #525252;
  background-color: #f9f8f9;
  font-family: "Urbanist";
  font-size: 15px;
}


.body-content {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.mobile-container {
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.body-content-upper {
  padding-left: 60px;
  padding-right: 60px;
}

.footer {
  /* background-color: rgba(174, 159, 177, 0.1); */
  background:  linear-gradient(#e4e4fc, #f9f8f9);
  width: 100%;
  height: 100px; 
  margin-top: auto;
}

.contacts-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.contacts-footer a {
  text-decoration: none;
}

.glitch-button {
  margin:0 auto;
  display: block;
}

a {
  color: #E256B7;
  font-weight: bold;
}

button a {
  color: #031927;
  text-decoration: none;
}

strong {
  color: #031927;
}

button {
  background-color: #E256B7;
  color: #031927;
  font-family: "Montserrat";
  padding: 5px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #031927;
  cursor: pointer;
}

.cv-button {
  display: block;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: -40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-top: 35px;
}

.news-container {
  height: 145px;
  margin: 5px;
  border: 2px solid #e4e4fc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.news-content-arrows {
  display: flex;
}

.news-arrow {
  margin-left: 0px !important;
}

.news-title {
  margin-top: -10px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #f9f8f9;
  font-weight: bold;
  width: fit-content;
}

.news-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: scroll;
  scrollbar-width: none;
}

.news-content::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  width: 0px;
}

.news-card {
  width: 220px;
  height: 120px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border: 1px solid rgba(174, 159, 177, 0.3);
  background:  linear-gradient(#e4e4fc, #f9f8f9);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.news-card-date {
  font-size: 12px;
  text-align: right;
}

.news-card-title {
  color: #E256B7;
  font-weight: bold;
}

.news-card-description {
  margin-top: 5px;
  background-color: #f9f8f9;
  overflow: hidden;
}

.news-card-dots {
  position: relative;
  margin-top: -28px;
  float:right;
  margin-right: 10px;
  font-size: 18px;
}

.news-detail {
  background-color: #f9f8f9;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 999;
  border: 1px solid rgba(174, 159, 177, 0.3);
  border-radius: 5px;
  padding: 20px;
  text-align: left;
  width: 500px;
}

.tech, .art {
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.page-paragraph {
  transition: opacity 0.3s ease; /* Add a smooth transition effect for opacity */
}

.page-paragraph.hidden {
  opacity: 0;
  pointer-events: none; /* Optionally disable pointer events for hidden sections */
}

.title {
  color: #E256B7;
}

.page-title {
  font-size: 18px;
  color: #E256B7;
}

.page-paragraph {
  font-size: 15px;
}

.gallery {
  margin-top: 15px;
}

.subgallery {
  display: flex;
  flex-flow: row wrap;
}

.gallery-subtitle {
  margin-top: 10px;
}

.galleryitem-title {
  font-family: "Montserrat";
  font-size: 17px;
  display: inline-block;
  width: 100%;
}

.galleryitem-title-1 {
  font-family: "Montserrat";
  font-size: 17px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.galleryitem-subtitle {
  margin-top: 5px;
}

.galleryitem-paragraph {
  margin-top: 5px;
}

.galleryitem-description {
  font-size: 15px !important;
}

.navbar {
  height: 50px;

  display: flex;
  justify-content: center;

  text-align: center;
}

.navelement {
  margin: 10px;
  padding: 5px;
  font-size: 16px;
  width: 105px;
  max-width: 105px;
  cursor: pointer;
}

.navelement a {
  color: inherit;
  text-decoration: none;
}

.navelement:hover {
  color: #E256B7;
}

.submenu {
  border: 1px solid rgba(174, 159, 177, 0.3);
  border-radius: 5%;
  background-color:  #f9f8f9;
  z-index: 999999;
  position: relative;
}

.submenuelement {
  font-size: 14px;
  z-index: 999999;
}


.submenuelement a {
  color: inherit;
  text-decoration: none;
}

.submenuelement:hover {
  color: #E256B7;
}

.galleryitem {
  display: flex;
  justify-content: baseline;
  margin: 10px;
}

.galleryitem img,
.galleryitem video {
  object-fit: contain;
}

.background {
  background-color: rgba(174, 159, 177, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
}

.galleryitemdetail {
  background-color: #f9f8f9;
  position: fixed;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 1px solid rgba(174, 159, 177, 0.3);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  height: 80%;
  width: 70%;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
}

.galleryitemdetail-header {
  width: 100%;
  display: flex;
  height: fit-content;
}

.galleryitemdetail-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.gallerydetail-content-image-container {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.gallerydetail-content-media {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallerydetail-content-media img {
  object-fit: contain;
  max-width: 95%;
  max-height: 95%;
}

.gallerydetail-content-media video {
  object-fit: contain;
  max-width: 95%;
  max-height: 95%;
}

.galleryitemdetail-collection-gallery {
  width: 8%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90%;
}

.galleryitemdetail-collection-gallery::-webkit-scrollbar {
  display: none;
}

.galleryitemdetail-collection-gallery video,
.galleryitemdetail-collection-gallery img {
  max-width: 88%;
  border: 2px solid rgba(174, 159, 177, 0.3);
  cursor: pointer;
}

.not-selected-media {
  filter: grayscale(100%);
}

.galleryitemdetail-description {
  width: 30%;
  text-align: left;
  margin: 10px;
  padding: 5px;
  overflow: scroll;
}

.galleryitemdetail-description::-webkit-scrollbar {
  display: none;
}

.gallery-arrow {
  width: 30px;
  height: 40px;
  align-self: center;
}

.gallerydetail-arrow-util {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.share {
  background-color: #f9f8f9;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 999;
  border: 1px solid rgba(174, 159, 177, 0.3);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  width: 350px;
  height: 150px;
}

.closebutton{
  text-align: center;
  text-justify: center;
  cursor: pointer;
  margin-bottom: 8px;
  border: 1px solid rgba(174, 159, 177, 0.3);
  background-color: rgba(174, 159, 177, 0.2);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-right: 0px;
  padding-bottom: 2px;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: auto;
  font-size: 12px;
}

.galleryitem-media {
  cursor: pointer;
}

.galleryitem-title{
  color: #E256B7;
}

.pagecontent {
  padding: 40px;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  justify-content: baseline;
  height: fit-content;
  min-height: 54vh;
}

.contentimage {
  width: 400px;
  height: 400px;
  background-color: transparent;
  margin-right: 60px;
}

.contentimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contentimage canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  width: 700px;
}

.arrow-icon {
  width: 15px;
  height: 20px;
  margin-bottom: -8px;
}

.arrow-left-icon, .arrow-right-icon {
  align-self: center;
  cursor: pointer;
}

.arrow-right-icon.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.arrow-right-icon.art-section-close {
  margin-bottom: -15px;
  width: 20px;
}

.arrow-left-icon {
  margin-left: -10px;
}

.share-icon {
  width: 15px;
  height: 15px;
  margin: 10px;
}

.social-icon {
  width: 15px;
  height: 15px;
}

.subtitle {
  font-size: 16px;
  margin-top: -2px;
  color: #031927;
}

.share-url {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border: 1px solid rgba(174, 159, 177, 0.3);
  padding: 5px;
  border-radius: 5px;
}

.copy-button {
  background-color: #525252;
  padding-bottom: 3px;
  padding-top: 5px;
  padding-left: 6px;
  padding-right: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.copied {
  background-color: #f9f8f9;
  position: absolute;
  z-index: 999;
  border: 1px solid rgba(174, 159, 177, 0.3);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 50px;
  font-size: 12px;
  font-weight: lighter;
}

.language-select {
  height: 20px;
  margin: 10px;
  margin-top: 12px;
  cursor: pointer;
  position: relative;
  padding-right: 17px;
  padding-left: 5px;
}

.arrow-icon-language {
  position: absolute;
  top: 4px;
  right: 0px;
  width: 17px;
  height: auto;
}

.social-icons-container a {
  text-decoration: none;
}

.social {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.social p {
  margin-left: 5px;
  color: #031927;
}

.loading-background {
  background-color: #525252;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  margin-left: -10px;
  margin-top: -10px;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f9f8f9;
  border-color: #f9f8f9 transparent #f9f8f9 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tech-subcategory {
  display: flex;
  flex-direction: row;
}

.tech-subcategory-left {
  margin-left: 10px;
  padding-top: 20px;
  width: 25vw;
  margin-right: 20px;
}

.tech-subcategory-right {
  padding-top: 20px;
  width: 60vw;
}

.tech-timeline {
  width: 1px;
  background-color: #031927;
  margin-right: 10px;
}

.tech-timeline-point {
  width: 11px;
  height: 11px;
  background-color: #031927;
  left: -16px;
  position: relative;
  margin-top: 30px;
  border-radius: 50%;
  z-index: -1;
}

.gallerycollection-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(174, 159, 177, 0.4);
  cursor: pointer;
}

.selected-dot {
  background-color: #031927;
}

.gallerycollection-dots {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: fit-content;
  background-color: white;
  gap: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}


/* For mobile phones/tablets: */

@media only screen and (max-width: 820px) {
  /* For mobile phones: */


  body {
    margin: 0;
    width: auto;
    height: 100vh;
  }

  .hamburger-menu-icon {
    width: 30px;
    height: 30px;
    margin: 30px;
  }

  .mobile-menu {
    position: absolute;
    top: 111px;
    left: 0px;
    right: 0px;
    text-align: center;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    padding: 10px;
    padding-top: 20%;
    height: 100vh;
    padding-top: 40px;
    z-index: 1;
    opacity: 100%;
    background-color: #f9f8f9;
  }

  .navelement {
    text-align: center;
  }

  .pagecontent {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    padding: 20px;
    height: fit-content;
  }

  .content {
    width: auto;
    margin-top: 10px;
  }

  .contentimage {
    width: 80vw;
    height: 80vw;
    margin: auto;
  }

  .gallery {
    width: 100%;
    height: auto;
  }

  .subgallery {
    height: auto;
  }

  .galleryitem {
    margin-left: 5px;
  }

  .galleryitem img,
  .galleryitem video {
    object-fit: contain;
    width: 150px;
  }

  .header {
    background-color: #f9f8f9;
    position: fixed;
    top: 0;
    padding: 10px;
    padding-top: 30px;
    width: 100vw;
    height: 70px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    margin-top: 0;
  }

  .mobile-under-sticky {
    margin-top: 111px;
    padding-top: 10px;
    overflow: hidden;
  }

  .news-detail {
    margin: auto;
    width: 80vw;
  }

  .tech, .art {
    padding: 20px;
    margin-bottom: 0;
  }

  .cv-button {
    display: block;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: -40px;
  }

  .galleryitemdetail {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .galleryitemdetail-content {
    display: flex;
    flex-direction: row;
    height: 97%;
  } 

  .gallerydetail-content-image-container {
    width: 100%;
    height: 60%;
  }

  .galleryitemdetail-collection-gallery {
    width: 100%;
    height: 8%;
    display: flex;
    gap: 3px;
    justify-content: flex-start;
  }

  .gallery-arrow {
    align-self: flex-end;
    width: 10px;
  }

  .gallerydetail-arrow-util {
    flex-direction: column;
  }

  .galleryitemdetail-description {
    text-align: center;
    margin: auto;
    width: 75vw;
    max-height: 30vh;
    overflow: scroll;
  }

  .arrow-left-icon {
    margin-left: -22px;
  }

  .closebutton {
    margin-right: auto;
  }

  .background {
    position: fixed;
    width: 100%;
    top: -100px;
    height: 120vh;
    overflow: hidden;
    overflow-y: hidden;
  }

  .share {
    left: 0;
    transform: none;
    margin: 0 auto;
    width: 92.3vw;
  }

  .share-url {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }

  .copied {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
  }

  .separator {
    margin: auto;
    height: 30px;
    width: 70%;
    opacity: 80%;
    background-image: url("./domain/images/blur2.png");
    background-size: 100% 100%;
    background-position: center;
    position: relative;
    z-index: -1;
  }

  :target:before {
    content: "";
    display: block;
    margin: 121px 0 0;
}

  .mobile-navigator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    padding: 10px;
  }

  .mobile-navigator a {
    text-decoration: none;
  }

  .mobile-navigator-arrow {
    width: 105px;
    height: 45px;
  }

  .mobile-navigator-arrow-internal-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Align horizontal */
    align-items: center; /* Align vertical */
  }

  .mobile-navigator-arrow-internal-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align horizontal */
    align-items: center; /* Align vertical */
  }

  .button-arrow-footer {
    background-color:rgba(174, 159, 177, 0.3);
    color: #031927;
    border-radius: 5px;
    border: 1px solid #031927;
    cursor: pointer;
  }

  .footer {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contacts-footer {
    bottom: 0;
    margin-bottom: 0;
  }
}


/* EASTER EGGS */

.glitch{
  position: relative;
  text-shadow: 
    0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

    animation: glitch 2000ms infinite;
}

@keyframes glitch {
  0% {
    text-shadow: 
    0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(25%, 10%) scale(0.85);
    opacity: 100%;
  }
  14% {
    text-shadow: 
    0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(25%, 10%) scale(0.85);
    opacity: 100%;
  }
  15% {
    text-shadow: 
    -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
    0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
    -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(-30%, -40%) scale(1.3);
    opacity: 30%;
  }
  49% {
    text-shadow: 
    -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
    0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
    -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(-30%, -40%) scale(1.3);
    opacity: 30%;
  }
  50% {
    text-shadow: 
    0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
    0.05em 0 0 rgba(0, 255, 0, 0.75),
    0 -0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(3%, -20%) scale(0.95);
    opacity: 100%;
  }
  99% {
    text-shadow: 
    0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
    0.05em 0 0 rgba(0, 255, 0, 0.75),
    0 -0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(3%, -20%) scale(0.95);
    opacity: 100%;
  }
  100% {
    text-shadow: 
    -0.025em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);

    transform: translate(23%, 20%) scale(1.15);
    opacity: 100%;
  }
}

.glitch-overlay-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch2 {
  position: absolute;
  top: 25%;
  left: 5%;
  width: 35%;
  height: 60%;
  opacity: 40%;
}

.broken-div {
  position: absolute;
  width: 350px;
  height: 200px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  display: flex;
}

.broken {
  margin: 50px;
  margin-left: 10px;
  width: 100px;
  height: auto;
}

/*
palette:
rosa: #E256B7
grigio: #525252
vecchio bianco: #F4F6EF
bianco: #f9f8f9
bluscuro: #031927
*/